// Sauvegarder ce fichier avec le nom config.js
const firebaseConfig = {
    apiKey: "AIzaSyBntQ8M87CZChQC3GVunnClxpnuqHJNSi8",
    authDomain: "cours-react-1f4a8.firebaseapp.com",
    projectId: "cours-react-1f4a8",
    storageBucket: "cours-react-1f4a8.appspot.com",
    messagingSenderId: "639939282455",
    appId: "1:639939282455:web:7c65c4a5fbf6e7d4ec7e52",
    measurementId: "G-VVE6N1WPH2"
};

export default firebaseConfig;